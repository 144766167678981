export const app = {
    namespaced: true,
    state: {
        showSpinner: false,
    },

    getters: {
        get_showSpinner: state => state.showSpinner
    },

    mutations: {
        mut_showSpinner: (state, payload) => {
            state.showSpinner = payload
        }
    }
}