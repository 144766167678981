<template>
<!--首页-->
  <div class="pageContent flexColumnHorzCenter">
    <div class="content flex justify-content-between align-items" id="topBannerNav" :style="topBannerNavBg">
      <div class="business flex align-items">
        <div class="title" @click="goHome">
          <img src="http://www.trans-life.com.cn/favicon.ico" alt="" />
        </div>
        <div class="nav_name flex">
          <div class="nav_name_item" :class="{ active_item: this.$route.name == 'Product' }" @click="goHome">
            首页
          </div>
          <!-- <div class="nav_name_item" :class="{ active_item: this.$route.name == 'Solution' }" @click="goSolution">
            公司介绍
          </div> -->
          <div class="nav_name_item" :class="{ active_item: this.$route.name == 'Support' }" @click="goSupport">
            公司优势
          </div>
          <div class="nav_name_item" :class="{ active_item: this.$route.name == 'Cooperation' }" @click="goCooperation">
            公司运营产品
          </div>
        </div>
      </div>
      <div class="control flex align-items">
        
      </div>
    </div>
    <img :src="item" alt="" v-for="(item, index) in imgList" :key="index">

    <div class="beian">
      <a href=" https://beian.miit.gov.cn" target="blank">
        Copyright©2014-2022  All Rights Reserved 深圳和能科技有限公司 版权所有 粤ICP备2021162889号-1
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import img1 from '../../assets/img_1.jpg'
import img2 from '../../assets/img_2.jpg'
import img3 from '../../assets/img_3.png'
import imgFooter from '../../assets/img_footer.png'

export default {
  name: "Index",
  data() {
    return {
      toastContent: '',
      topBannerNavBg: {
        backgroundColor: '',
        boxShadow: ''
      },

      imgList: [
        img1,
        imgFooter
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    ...mapMutations('app', ['mut_showSpinner']),

    showLoading() {
      this.mut_showSpinner(true)
    },

    hideLoading() {
      this.mut_showSpinner(false)
    },

    showToast() {
      this.$toast(this.toastContent)
    },

    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.topBannerNavBg.backgroundColor = '#ffffff' // scrollTop + 多少根据自己的需求设置
      } else if (scrollTop === 0) {
        this.topBannerNavBg.backgroundColor = 'transparent' // 设置回到顶部时，背景颜色为透明
      }
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },

    goHome() {
      this.$router.push({ name: "Home" });
    },
    goProduct() {
      // this.$router.push({ name: "Product" });
    },
    goSolution() {
      // this.$router.push({ name: "Solution" });
    },
    goSupport() {
      this.$router.push({ name: "Support" });
    },
    goCooperation() {
      this.$router.push({ name: "Cooperation" });
    },
  }
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.pageContent {
  position: relative;

  .content {
    font-family: "pingfang";
    //margin: 0 auto;
    // width: 1208px;
    width:100%;
    height: 72px;
    // min-width: 1208px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    box-sizing: border-box;
    padding: 0 20%;

    .business {
      .title {
        margin-right: 24px;
        cursor: pointer;

        img {
          height: 39px;
        }
      }

      .nav_name {
        .nav_name_item {
          cursor: pointer;
          margin-left: 24px;
          font-size: 16px;
          //font-weight: bold;
          color: rgba(13, 26, 38, 0.6);

          &:hover {
            //font-family: pingfangMedium;
            font-weight: 600;
            color: #0d1a26;
            //color: #3B5DEC;
          }
        }

        .active_item {
          font-weight: 600;
          color: #0d1a26;
        }
      }
    }

    .control {
      margin-right: 44px;

      .control_name {
        cursor: pointer;
        margin-right: 24px;
        font-size: 16px;
        //font-weight: 300;
        color: rgba(13, 26, 38, 0.6);

        img {
          width: 31px;
          border-radius: 50%;
        }
      }
    }
  }

  .beian {
    color: white;
    font-size: 14px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
