import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Antd-vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

//全局样式
import './global/css/main.css'
import './global/css/global.scss'
import './global/css/plugin.scss'
import './global/css/reset.scss'

//fastclick
import fastclick from 'fastclick'
fastclick.attach(document.body)

//吐司、加载框
import {Toast,Loading} from "./utils/plugin";
Vue.use(Toast);
Vue.use(Loading);

Vue.use(Antd)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
