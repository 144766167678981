<template>
  <div id="app">
    <!--    全局loading-->
    <div class="spinContainer flexRowAllCenter">
      <a-spin size="large" tip="" wrapperClassName="spin" v-show="get_showSpinner"/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data () {
    return {

    }
  },

  computed: {
    ...mapGetters('app', ['get_showSpinner'])
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.spinContainer{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  .spin{
    position: fixed;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
</style>
